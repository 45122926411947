import { ImgSize } from '@/components/BaseImage.vue'

const defaultMobileScreenSize = 375
const breakpoints = {
  xs: 0,
  sm: 414,
  md: 768,
  lg: 1280,
  xl: 1340,
  xxl: 1600
}

type Breakpoint = keyof typeof breakpoints
type SizeMap = Partial<Record<Breakpoint, ImgSize>>

export function getImageSizes(sizes: SizeMap) {
  return {
    imgSizes: Object.values(sizes),
    sizes: Object.entries(sizes)
      .map(
        ([breakpoint, size]) =>
          // swap "lg" to 1280 etc
          // [1280, { w, h }]
          [breakpoints[breakpoint as Breakpoint], size] as const
      )
      .sort(([a], [b]) => b - a)
      .map(([screenSize, { w }]) => {
        const percentage = (w / (screenSize || defaultMobileScreenSize)) * 100
        const imageSize = `${Math.round(percentage)}vw`
        return screenSize
          ? `(min-width: ${screenSize}px) ${imageSize}`
          : imageSize
      })
      .join(', ')
  }
}
